import React, { useState, useEffect } from 'react';
import ChatIcon from '@mui/icons-material/Chat';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, CircularProgress, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import httpProvider from '../provider/HttpProvider';

export default function GPTDialog() {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [answer, setAnswer] = useState('');
    const [error, setError] = useState(null);
    const [conversationId, setConversationId] = useState(null);

    useEffect(() => {
        const fpPromise = FingerprintJS.load();
        fpPromise
            .then(fp => fp.get())
            .then(result => {
                const visitorId = result.visitorId;
                setConversationId(visitorId); // Set visitor ID as conversation ID
                console.log('Visitor ID (conversation ID):', visitorId);
            })
            .catch(error => {
                console.error('Error loading fingerprint:', error);
                setError('Failed to load visitor ID');
            });
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
        setAnswer('');
        setError(null);
    };

    const handleClose = () => {
        setOpen(false);
        setMessage('');
        setError(null);
        setAnswer('');
    };

    const handleSend = () => {
        setLoading(true);
        const apiUrl = '/api/openai/chat';
        const sanitizedMessage = message.replace(/\n/g, ' ').trim();

        const requestData = {
            question: sanitizedMessage,
            conversation_id: conversationId,
        };

        httpProvider.post(apiUrl, requestData, {
            headers: {
                'Content-Type': 'application/json',
            },
            timeout: 10000,
        })
            .then((response) => {
                if (response && response.message && response.message.answer) {
                    setAnswer(response.message.answer);
                    setError(null);
                } else {
                    setError('Unexpected response format. Please check the API response.');
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error:', error);
                setError('Message sending failed. Please try again.');
                setLoading(false);
            });
    };

    return (
        <div>
            <MenuItem onClick={handleClickOpen} title='GPT'>
                <ChatIcon />
            </MenuItem>
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>GPT</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        multiline
                        rows={4}
                        margin="dense"
                        label="Message"
                        fullWidth
                        variant="outlined"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />

                    {loading ? (
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                            <CircularProgress size={24} />
                            <Typography variant="body2" style={{ marginLeft: 10 }}>Sending...</Typography>
                        </div>
                    ) : answer ? (
                        <Typography variant="body1" style={{ marginTop: 10, color: 'green' }}>
                            Response: {answer}
                        </Typography>
                    ) : error ? (
                        <Typography variant="body1" style={{ marginTop: 10, color: 'red' }}>
                            {error}
                        </Typography>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSend} color="primary" variant="contained" disabled={loading || !conversationId}>
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
