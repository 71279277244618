import React from "react";
import { Link } from "react-router-dom";

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import LogoutIcon from '@mui/icons-material/Logout';
import TerminalIcon from '@mui/icons-material/Terminal';

import { useAuth } from "../provider/AuthProvider";
import ChatDialog from "./ChatDialog";

export default function MainMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { getToken } = useAuth();

  if (getToken() == null) {
    return <p>You are not logged in. <small><Link to="/login">Login</Link></small></p>;
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={0}
      sx={{ marginTop: 2 }}
    >
      <Grid item>
        <ChatDialog />
      </Grid>
      <Grid item>
        <MenuItem onClick={handleClose} component={Link} target="_blank" to="https://anakin.blackpixelworks.com/" title='Anakin'>
          <TerminalIcon sx={{ fontSize: 30 }} />
        </MenuItem>
      </Grid>
      <Grid item>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
        >
          Menu <MenuIcon />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={handleClose} component={Link} to="/" title='Home'>Home</MenuItem>
          <MenuItem onClick={handleClose} component={Link} to="/dash/" title="Dash">Dash</MenuItem>
          <MenuItem onClick={handleClose} component={Link} to="/tasks/" title='Tasks'>Tasks</MenuItem>
          <MenuItem onClick={handleClose} component={Link} to="/weather/" title='Weather'>Weather</MenuItem>
          <MenuItem onClick={handleClose} component={Link} to="/bookmarks/" title='Bookmarks'>Bookmarks</MenuItem>
          <MenuItem onClick={handleClose} component={Link} to="/tags" title='Tags'>Tags</MenuItem>
        </Menu>
      </Grid>
      <Grid item>
        <MenuItem onClick={handleClose} component={Link} to="logout" title='Logout'>
          <LogoutIcon sx={{ fontSize: 30 }} />
        </MenuItem>
      </Grid>
    </Grid>
  );
}
